<template>
    <div class="container">
        <div class="simple-page verify">
            <div class="page-title">请选择认证方式</div>
             <div class="choice"><div :class="selectcard?'active':''" @click="selectcard=true" >身份认证</div><div :class="!selectcard?'active':''" @click="selectcard=false" >护照认证</div></div>
            <div class="page-desc">为了保护您音乐作品版权，请完成实名认证</div>
            <div class="uploads">
                <div  v-if="selectcard" class="item" :class="canSubmit?'itemSelect':''">
                    <div class="title" v-if="!canSubmit">请上传身份证正面</div>
                    <div class="desc"  v-if="!canSubmit">请保证证件完整且文字清晰</div>
                    <div class="picture">
                        <img :src="idcard1" class="img"  alt="身份证"/>
                        <div class="btn flex-center">
                            {{canSubmit ? '重新上传' : '上传照片'}}
                            <input type="file" class="cover" @change="uplaodFile($event, 1)" />
                        </div>
                    </div>
                </div>
              <div  v-if="selectcard"  class="item" :class="canSubmit?'itemSelect':''" >
                    <div class="title" v-if="!canSubmit">请上传身份证反面</div>
                    <div class="desc"  v-if="!canSubmit">请保证证件完整且文字清晰</div>
                    <div class="picture">
                        <img :src="idcard2" class="img"  alt="身份证"/>
                        <div class="btn flex-center">
                            {{canSubmit ? '重新上传' : '上传照片'}}
                            <input type="file" class="cover" @change="uplaodFile($event, 2)" />
                        </div>
                    </div>
                </div>
                <div  v-if="!selectcard" style="margin-left:0px;"  class="item" :class="passSubmit?'itemSelect':''" >
                    <div class="title" v-if="!passSubmit">请上传护照带头像页</div>
                    <div class="desc"  v-if="!passSubmit">请保证证件完整且文字清晰</div>
                    <div class="picture">
                        <img :src="idcard3" class="img"  alt="身份证"/>
                        <div class="btn flex-center">
                            {{passSubmit ? '重新上传' : '上传照片'}}
                            <input type="file" class="cover" @change="uplaodFile($event, 3)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail" v-if="showDetail&&selectcard">
                <div class="title">请确认以下信息是否正确</div>
                <div class="form">
                    <div class="form-item">
                        <div class="label">姓名</div>
                        <input class="input"  v-model="form.name" />
                    </div>
                    <div class="form-item">
                        <div class="label">身份证号</div>
                        <input class="input"  v-model="form.cardNo" />
                    </div>
                    <div class="form-item">
                        <div class="label">证件到期日</div>
                        <input class="input" v-model="form.expireDate" />
                    </div>
                </div>
            </div>
            <div class="passport" v-if="passportShowDetail&&!selectcard">
                <div class="title">请确认以下信息是否正确</div>
                <verifyport :passfrom.sync="passform"></verifyport>
            </div>
            <div class="form-action flex-center">
                <dbutton :disabled="selectcard?!canSubmit:!passSubmit" @click="submit">提交审核</dbutton>
            </div>
        </div>
    </div>
</template>
<script>
// import result from '@/components/Result.vue'
import idcard1 from '../assets/idcard1.png'
import idcard2 from '../assets/idcard2.png'
import verifyport from '../components/verifyport'
export default {
    components: {verifyport},
    data(){
        return {
            idcard1: idcard1,
            idcard2: idcard2,
            idcard3: idcard1,
            showDetail: false, // 是否显示详情
            passportShowDetail:false, //是否显示护照详情
            submited: true, //提交按钮节流
            verifying: false, // 正在验证中
            form: {
                name: '',
                authority: '', //发证机关
                birthday: '',
                cardNo: '',
                expireDate: '',
                idBackUrl: '',
                idFrontUrl: '',
                sex: ''
            },
            passform: {
                Name:'',//名字
                Sex:'',//性别
                birth:'',//出生日期
                Nation:'',//国籍
                expireDate:'',//证件到期时间
                IssuingCountry:'',//发行国
                cardNo:''//护照id
            },
            certifyData: null,
            //选项卡片
            selectcard:true
        }
    },
    computed:{
        canSubmit(){
            return this.showDetail
        },
        passSubmit(){
           return this.passportShowDetail
        },
        phonemobile(){
            return this.$store.state.user.mobile
        }
    },
    created(){
         if(localStorage['certifyData'+this.phonemobile ]){
            this.certifyData = JSON.parse(localStorage['certifyData'+this.phonemobile])
            this.certifyData.role = this.certifyData.role.join(',')

        }else{
                 this.$router.replace("/certify")
        }
        // 认证状态 0未提交 1已提交，待审核 2审核失败 3审核成功
        // 有1 表示已提交过了
        if(this.$route.query.status == 1 || this.user.verifiedStatus === 1){
            return this.verifying = true
        }

    },
    methods:{
        // type 1正面，2反面 3护照
        async uplaodFile(e, type){
            if(!e.target.files.length){
                return this.$toast('请选择图片')
            }
            let file = e.target.files[0]
            let data = new FormData
            data.append('type', type)
            data.append('file', file)
            e.srcElement.type='text'
            let res = await this.$post("/api/user/ocrCard", data)
            e.srcElement.type='file'
            if(type == 1){
                let data = res.data.ocrCard
                if(data==null){
                this.$popup({
                    msg: '请上传正确的身份证正面照',
                    type: 'warning'
                    });
                    return false
                }
              this.form.idFrontUrl = res.data.url
                this.form.name = data.Name
                this.form.birthday = data.Birth
                this.form.cardNo = data.IdNum
                this.form.sex = data.Sex
                this.idcard1 = res.data.url
            }else if(type == 2){
              if(res.data.ocrCard==null){
                this.$popup({
                    msg: '请上传正确的身份证反面照',
                    type: 'warning'
                    });
                    return false
                }
                this.form.idBackUrl = res.data.url
                this.form.authority = res.data.ocrCard.Authority
                this.form.expireDate = res.data.ocrCard.ValidDate
                this.idcard2 = res.data.url
            }else{
             if(res.data.ocrCard==null){
              this.$popup({
                    msg: '请上传正确护照证件',
                    type: 'warning'
                    });
                    return false
             }
             this.idcard3 = res.data.url
            let {Birth,IdNum,IssuingCountry,Name,Nation ,Sex,ValidDate}=res.data.ocrCard;
            //   Birth
            //   expireDate
            // 日期格式XXXX/XX/XX换成XXXX.XX.XX
              this.passform={

                  Birth:Birth.replaceAll('/','.'),cardNo:IdNum,IssuingCountry,name:Name,Nation,Sex,expireDate:ValidDate.replaceAll('/','.'),passportUrl:res.data.url

              }
            }
              if(this.selectcard){
                 this.showDetail = true;
             }else{
                 this.passportShowDetail=true;
             }
        },
        async submit(){
            if(!this.submited){
                return
            }
             this.submited = false;
            let p={}
            if(this.selectcard){
                p=Object.assign({cardType:0}, this.certifyData, this.form)
            }else{
                p=Object.assign({cardType:1}, this.certifyData, this.passform)
            }
            let res = await this.$post('/api/user/submitCertification',p )

            this.submited = true;
            // 修改认证状态
            this.$store.commit('UPDATE_VERIFIED_STATUS', 1)
            if(res.code==0){
              this.$store.dispatch('getMechanismList');
                let local='certifyData'+this.phonemobile
                localStorage.removeItem(local)
                this.$post('/api/user/userMsg').then(res => {
                this.$store.commit('UMSG',res.data)
                  if(res.data.webLoginType==0){
                  this.$post('/api/company/identityChange', {
                    id: res.data.userCompanyVo.id,
                    type: 0
                  }).then((res) => {
                  let data = res.data
                  if (res.code == 0) {
                    this.$store.commit('LOGIN', {
                      secret: data.secret,
                      session: data.session,
                      user: data.user
                    })
                  }
                })
                  }
                })
            this.$router.push({path:'/musiciancon',query:{flag:true }})
            }
        }
        /* onClick(e){
            console.log('onClick', e)
        } */
    }
}
</script>
<style scoped lang="scss">
@import '../styles/simple-page.scss';
.submited{
    height: 834px;

}
.verify{
    padding: 50px 120px !important;
    .choice{
        display:flex;
        margin-bottom: 40px;
        cursor: pointer;
        div{
            width:130px;
            height:50px;
            border:1px solid #f8f8f8;
            margin-right: 20px;
            line-height:50px;
            text-align:center;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #666666;
            letter-spacing: 0px;
             font-size: 15px;
            border-radius: 3px;
            background: #f8f8f8;
        }
        > .active{
            background-color: #ffffff;
            border: 1px solid #ff004d;
             background-image: url('../assets/tablebelow.png');
             background-repeat: no-repeat;
             background-size: 16px 16px;
             background-position: right bottom;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #ff004d;
            letter-spacing: 0px;
            cursor:default ;

            }
    }
    .uploads{
        display: flex;
        padding-bottom: 60px;

        .item{
            height:340px;
            box-sizing: border-box;
            padding: 40px 89px;
            background: #FAFAFA;
            &:last-child{
                margin-left: 57px;
            }
            .title{
                // padding-top: 20px;
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 22px;
            }
            .desc{
                margin-top: 8px;
                height: 14px;
                font-size: 10px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #333333;
                line-height: 14px;
            }
            .picture{
                width: 242px;
                height: 153px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 47px 0;
                .img{
                    width: 242px;
                    height: 153px;
                    display: block;
                }
                .btn{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    z-index: 1;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    height: 29px;
                    background: $base-color;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 17px;
                    .cover{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                    }
                }
            }
        }
        .itemSelect{
            height:248px;
            padding:47px 89px;
            box-sizing: border-box;
            .picture{
                padding: 0px;
            }
        }
    }
    .detail{
        .title{
            height: 16px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
        }
        .form{
            .form-item{
                display: flex;
                border-bottom: 1px solid #E8E8E8;
                padding-bottom: 13px;
                margin-top: 40px;
                &:first-child{
                    margin-top: 30px;
                }
                .label{
                    width: 97px;
                        height: 20px;
                        font-size: 14px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                        line-height: 20px;
                }
                .input{
                    flex: 1;
                    width: 100%;
                    border: none;
                    outline: none;
                    height: 16px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 16px;
                }
            }
        }
    }
          .passport{
                .title{
                    font-size: 14px;
                    font-family: PingFang SC, PingFang SC-Regular;
                    font-weight: 400;
                    text-align: LEFT;
                    color: #666666;
                    line-height: 16px;
                }
            }

    .form-action{
        padding: 60px;
    }
}
</style>
