<template>
  <ul>
    <li><div class="lable">名字</div><input type="text" v-model="passfrom.name" ></li>
    <li><div class="lable">性别</div><input type="text" v-model="passfrom.Sex" ></li>
    <li><div class="lable">出生日期</div><input type="text" v-model="passfrom.Birth" ></li>
    <li><div class="lable">国籍</div><input type="text" v-model="passfrom.Nation" ></li>
    <li><div class="lable">证件到期日</div><input type="text" v-model="passfrom.expireDate"></li>
    <li><div class="lable">发行国</div><input type="text" v-model="passfrom.IssuingCountry"></li>
  </ul>
</template>
<script>
export default {
     props:["passfrom"],
}
</script>
<style scoped lang="scss">
  ul{
      margin: 0px;
      padding: 0px;
      display:flex;
      flex-wrap: wrap;
      justify-content:space-between;

      li{  width:420px;
         border-bottom:1px solid #e8e8e8;
          padding-bottom: 15px;
          padding-top: 40px;
            list-style:none;
            display: flex;
            // margin-right: 50px;
            .lable{
                width: 97px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Semibold;
                font-weight: 600;
                text-align: LEFT;
                color: #333333;
            }
            input{
                border:none;
                outline: none;
                height: 16px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: LEFT;
                color: #999999;
                line-height: 16px;
            }
      }
  }
</style>
